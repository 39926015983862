import React from 'react'
import appConfig from '../appConfig'
// import scrollTo from 'gatsby-plugin-smoothscroll'
// import { Carousel } from 'react-responsive-carousel'
// import 'react-responsive-carousel/lib/styles/carousel.min.css'

// const globalDarkenPercentage = '20'
// const defaultSlides = [
//   {
//     id: 1,
//     darkenPercentage: globalDarkenPercentage, // '10%'
//     image: 'https://imgs.kuboshow.de/wp/KUBO_2022_Slider_1.jpg',
//     overlayImage: globalOverlayStempel,
//     textSmall: false,
//     styleOverride: { backgroundPositionX: '52%' },
//   },
//   {
//     id: 2,
//     darkenPercentage: globalDarkenPercentage,
//     image: 'https://imgs.kuboshow.de/wp/KUBO_2022_Slider_2.jpg',
//     overlayImage: globalOverlayStempel,
//     textSmall: false,
//     styleOverride: { backgroundPositionX: '70%' },
//   },
//   {
//     id: 3,
//     darkenPercentage: globalDarkenPercentage,
//     image: 'https://imgs.kuboshow.de/wp/KUBO_2022_Slider_3.jpg',
//     overlayImage: globalOverlayStempel,
//     textSmall: false,
//     styleOverride: { backgroundPositionX: '70%' },
//   },
//   {
//     id: 4,
//     darkenPercentage: globalDarkenPercentage,
//     image: 'https://imgs.kuboshow.de/wp/KUBO_2022_Slider_4.jpg',
//     overlayImage: globalOverlayStempel,
//     textSmall: false,
//     styleOverride: { backgroundPositionX: '90%' },
//   },
//   {
//     id: 5,
//     darkenPercentage: globalDarkenPercentage,
//     image: 'https://imgs.kuboshow.de/wp/KUBO_2022_Slider_5.jpg',
//     overlayImage: globalOverlayStempel,
//     textSmall: false,
//   },
// ]

// const arrowStyles = {
//   position: 'absolute',
//   zIndex: 2,
//   top: 'calc(50% - 30px)',
//   width: 30,
//   height: 60,
//   cursor: 'pointer',
//   fontSize: '3rem',
//   color: '#a9a9a9',
//   background: 'transparent',
// }

const HeroSlider = () => {
  // const slides = defaultSlides

  return (
    <div>
      <div
        className="p-relative w-100 vh-100 d-sm-flex d-none justify-content-between align-items-center"
        style={{
          // backgroundColor: 'rgb(240,240,240)',
          backgroundImage: `url(${appConfig.images.headerBgImage})`,
          backgroundSize: 'cover',
          backgroundPositionX: '32%',
        }}
      >
        {/* <div className={`content-overlay w-60 h-40`}> */}
        <img
          src={appConfig.images.globalOverlayStempel}
          alt="KUBOSHOW 2022"
          className="h-60 w-auto ml-5"
        />
        {/* </div> */}
        {/* <div className={`content-overlay w-40 h-40`}>
            <img
              src="https://imgs.kuboshow.de/wp/KUBO_2023_Slider9.jpg"
              alt="kuboshow 2022"
              className="w-100"
            />
          </div> */}
      </div>
      <div
        className="p-relative w-100 d-flex d-sm-none justify-content-center align-items-center overflow-hidden"
        style={{ height: '50vh' }}
      >
        <img
          src={appConfig.images.headerBgImage}
          alt="KUBOSHOW 2024"
          className="w-auto h-100"
        />
        {/* <div className={`content-overlay w-100 h-60`}>
          <img
            src="https://imgs.kuboshow.de/wp/KUBO_2023_Slider9.jpg"
            alt="kuboshow 2022"
            className="w-auto h-100"
          />
        </div>
        <div
          className={`content-overlay w-100 h-auto d-flex align-items-center`}
        >
          <img
            src="https://imgs.kuboshow.de/wp/KUBO_2023_1_Stempel_test.png"
            alt="kuboshow 2022"
            className="w-100"
          />
        </div> */}
      </div>
    </div>
  )
}

export default HeroSlider
