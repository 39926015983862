const appConfig = {
  previewGalleryAnnouncement: true,
  showApplicationMaintenance: false,
  showPurchaseRequestButtons: false,
  showPurchaseRequestButttonsTime: new Date('2025-05-26T16:00:00+00:00'),
  includePorto: true,
  showApplication: true,
  showApplicationViaLogin: true,
  showApplicationViaLoginForSome: [],
  showApplicationAnnouncement: false,
  showApplicationOver: false,
  showConfirmContract: false,
  restrictedMode: false,
  currentFairYear: '2024-1',
  links: {
    purchaseRequestGTC:
      'https://firebasestorage.googleapis.com/v0/b/imgs.kuboshow.de/o/AGBs_KUBOSHOW_KUNSTMESSE_23-1.pdf?alt=media',
  },
  functions: {
    onApplicationSent:
      'https://europe-west1-kuboshow-gdt.cloudfunctions.net/onApplicationSent',
    onPurchaseRequest:
      // 'http://localhost:5001/kuboshow-gdt/europe-west1/onPurchaseRequest',
      'https://europe-west1-kuboshow-gdt.cloudfunctions.net/onPurchaseRequest',
  },
  images: {
    // globalOverlayStempel: 'https://imgs.kuboshow.de/wp/KUBO_leer_selfmade.png',
    globalOverlayStempel:
      'https://dl3.pushbulletusercontent.com/pkE7CXERxhi88wvDRFfT0fnrl719rS0j/KUBO_2024_Logo_RZ.png',
    headerBgImage: 'https://dl3.pushbulletusercontent.com/jUy7qlKeYiJhqtinsJH6XYE2mZ2QlwOp/KUBO_2023_Urakami_Cover_2.jpg',
  },
}

export default appConfig
